import employee from '@/api/human-resource/employee'
import { baseState, baseMutations } from '@/store/mixins'

import { isEmpty, split } from 'lodash'

export const state = {
    ...baseState,
    positions: [],
    status: [],
    statusReason: [],
    shifts: [],
    pcDates: [],
    paymentTypes: [],
    nationalities: [],
    branches: [],
    provinces: [],
    levels: [],
    salary_levels: [],
    staffTypes: [],
    employeeTypes: [],
    status_id: 'All',
    branch_id: undefined,
    formViewDatas: [],
}

export const getters = {}

export const mutations = {
    ...baseMutations,
    SET_POSITION(state, positions) {
        state.positions = positions
    },
    SET_STATUS(state, data) {
        state.status = data
    },
    SET_STATUS_REASON(state, data) {
        state.statusReason = data
    },
    SET_SHIFTS(state, data) {
        state.shifts = data
    },
    SET_PC_DATES(state, data) {
        state.pcDates = data
    },
    SET_PAYMENT_TYPES(state, data) {
        state.paymentTypes = data
    },
    SET_NATIONALITIES(state, data) {
        state.nationalities = data
    },
    SET_BRANCHES(state, data) {
        state.branches = data
    },
    SET_PROVINCES(state, data) {
        state.provinces = data
    },
    SET_LEVELS(state, data) {
        state.levels = data
    },
    SET_SALARY_LEVELS(state, data) {
        state.salary_levels = data
    },
    SET_STAFF_TYPE(state, data) {
        state.staffTypes = data
    },
    SET_EMPLOYEE_TYPE(state, data) {
        state.employeeTypes = data
    },
    SET_STATUS_ID(state, data) {
        state.status_id = data
    },
    SET_BRANCH_ID(state, data) {
        state.branch_id = data
    },
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formViewDatas = data;
        } else {
            split(index, ",").forEach(el => {
                console.log(state.formViewDatas[el]);
                state.formViewDatas[el] = data[el];
            });
        }
    },
}

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                status_id: context.state.status_id,
                branch_id: context.state.branch_id,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        )
        return employee.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },

    store(context, data) {
        return employee.store(data)
    },

    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await employee.find(id)
            context.commit('SET_EDIT_DATA', response.data)
        }
    },

    update(context, { id, data }) {
        return employee.update(id, data)
    },

    destroy(context, id) {
        return employee.destroy(id)
    },

    photoUpload(context, data) {
        return employee.photoUpload(data)
    },
    async getPosition(context, attributes = {}) {
        let response = await employee.position({ params: attributes })
        context.commit('SET_POSITION', response.data)
    },
    async studentStatus(context, attributes = {}) {
        let response = await employee.studentStatus({ params: attributes })
        context.commit('SET_STATUS', response.data)
    },
    async getStatus(context, attributes = {}) {
        let response = await employee.status({ params: attributes })
        context.commit('SET_STATUS', response.data)
    },

    async getStatusReason(context, attributes = {}) {
        let response = await employee.statusReason({ params: attributes })
        context.commit('SET_STATUS_REASON', response.data)
    },

    async getShift(context, attributes = {}) {
        let response = await employee.shift({ params: attributes })
        context.commit('SET_SHIFTS', response.data)
    },

    async getPcDate(context, attributes = {}) {
        let response = await employee.pcDate({ params: attributes })
        context.commit('SET_PC_DATES', response.data)
    },

    async getPaymentType(context, attributes = {}) {
        let response = await employee.paymentType({ params: attributes })
        context.commit('SET_PAYMENT_TYPES', response.data)
    },
    async getNationality(context, attributes = {}) {
        let response = await employee.nationality({ params: attributes })
        context.commit('SET_NATIONALITIES', response.data)
    },
    async getLevel(context, attributes = {}) {
        let response = await employee.level({ params: attributes })
        context.commit('SET_LEVELS', response.levels)
        context.commit('SET_SALARY_LEVELS', response.salary_levels)
    },
    async getBranch(context, attributes = {}) {
        let response = await employee.branch({ params: attributes })
        context.commit('SET_BRANCHES', response.data)
    },
    async getStaffType(context, attributes = {}) {
        let response = await employee.staffType({ params: attributes })
        context.commit('SET_STAFF_TYPE', response.data)
    },
    async getEmployeeType(context, attributes = {}) {
        let response = await employee.employeeType({ params: attributes })
        context.commit('SET_EMPLOYEE_TYPE', response.data)
    },
    moveBranch(context, data) {
        return employee.moveBranch(data)
    },
    storeContract(context, data) {
        return employee.storeContract(data)
    },
    previewPdf(context, attributes = {}) {
        return employee.previewPdf({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    addVillage(context, data) {
        return employee.addVillage(data)
    },
    async getProvince(context) {
        let response = await employee.province()
        context.commit('SET_PROVINCES', response.data)
    },
    getDistrict(context, province_code) {
        if (!province_code) return
        return employee.district(province_code)
    },
    getCommune(context, district_code) {
        if (!district_code) return
        return employee.commune(district_code)
    },
    getVillage(context, commune_code) {
        if (!commune_code) return
        return employee.village(commune_code)
    },
    documentType(context, data) {
        return employee.documentType(data)
    },
    removeFileUploaded(context, attributes) {
        return employee.removeFile({
            params: attributes
        })
    },
    printCard(context, data) {
        return employee.printCard({
            params: {
                side: data.side,
                staff_id_list: data.staff_id_list,
                type: data.type,
                expired_date: data.expired_date,
            }
        })
    },
    expiredDateCard(context, data) {
        return employee.expiredDateCard({
            params: {
                staff_id_list: data.staff_id_list,
            }
        })
    },
    async fectBranch(context, attributes = {}) {
        let response = await employee.getBranch({ params: attributes })
        context.commit('SET_BRANCHES', response.data)
    },
    async createReason(context, data) {
        let response = await employee.createReason(data)
        context.commit('SET_STATUS_REASON', response.data)
        return response
    },
    async getFormViewModel(context, { params, index }) {
        let response = await employee.formViewModel({ params });
        context.commit("SET_VIEW_MODELS", {
            data: response.data,
            index: index
        });
        return Promise.resolve(response);
    },
}
