import http from "@/http-client";

export const get = (Options = {}) => {
    return http
        .get("/api/users", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const find = (id, Options = {}) => {
    return http
        .get(`/api/users/${id}`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return http
        .post("/api/users", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return http
        .put(`/api/users/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return http
        .delete(`/api/users/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const currentUser = (Options = {}) => {
    return http
        .get("/api/current-users", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const userRole = () => {
    return http
        .get("/api/users/roles")
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const branch = () => {
    return http
        .get("/api/users/branches")
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const employee = (Options = {}) => {
    return http
        .get("/api/users/employees", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const permission = () => {
    return http
        .get("/api/users/permissions")
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const branchAssigned = (Options = {}) => {
    return http
        .get("/api/users/branch-assigned", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const changeDefaultBranch = data => {
    return http
        .post("/api/users/switch-default-branches", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const unlock = data => {
    return http
        .post("/api/users/unlock", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const changePassword = data => {
    return http
        .post("/api/users/change-password", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const passwordReset = (id, data) => {
    return http
        .put(`/api/users/${id}/reset-passwords`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}


export const getLogo = (Options = {}) => {
    return http
        .get('/api/users/get-logos', Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const exportExcel = attributes => {
    return http
        .post('/api/users/export', attributes, {
            responseType: 'arraybuffer'
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}

export const branchAssignedForFilter = (Options = {}) => {
    return http
        .get('/api/users/branch-assigned-for-filter', Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const appUrl = (Options = {}) => {
    return http
        .get("/api/app-url", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    find,
    store,
    update,
    destroy,
    currentUser,
    userRole,
    branch,
    employee,
    permission,
    branchAssigned,
    changeDefaultBranch,
    unlock,
    changePassword,
    passwordReset,
    getLogo,
    exportExcel,
    appUrl,
    branchAssignedForFilter
};
