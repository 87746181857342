import teachingCalendar from '@/api/human-resource/teaching-calendar'
import { baseState, baseMutations } from '@/store/mixins'
import moment from 'moment'

export const state = {
	...baseState,
	date_from: moment()
		.add(-1, 'months')
		.format('DD-MM-YYYY'),
	date_to: moment()
		.format('DD-MM-YYYY')
}

export const getters = {
	tConvert: () => time => {
		if (time === null || time === undefined) return null
		// Check correct time format and split into components
		time = time
			.toString()
			.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

		if (time.length > 1) {
			// If time format correct
			time = time.slice(1) // Remove full string match value
			time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
			time[0] = +time[0] % 12 || 12 // Adjust hours
		}
		return time.join('') // return adjusted time or original string
	}
}

export const mutations = {
	...baseMutations,
}

export const actions = {
	fetch(context, attrubutes = {}) {
		let params = Object.assign(
			{
				page: context.state.pagination.page,
				perPage: context.state.pagination.perPage,
				search: context.state.search,
				date_range: [context.state.date_from, context.state.date_to]
			},
			attrubutes
		)
		return teachingCalendar.get({ params }).then(response => {
			context.commit('RECEIVE_RESOURCES', response)
			return Promise.resolve(response)
		})
	}
}
